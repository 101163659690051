<template>
  <ion-menu mode="md" side="start" menu-id="first" content-id="main" swipe-gesture="false">
    <ion-header>
      <ion-toolbar>
        <!-- <ion-title>{{ storedUserProfile.email }}</ion-title> -->

        <!-- <ion-chip> -->
        <ion-item
          @click.stop="viewUserProfile"
          style="--background: none;
          color: white;
          font-size: 1.1rem !important;
          --min-height: none;"
        >

          <!-- <input
            type="file"
            @input="handleFiles($event)"
            style="display:none"
            id="getProfilePic"
            accept="image/png, image/jpeg"
          /> -->

          <!-- clickToSelectFiles -->
          <!-- @click.stop="viewUserProfile" -->
          <ion-avatar
            v-if="this.checkForProfilePic()"
            style="margin-inline-end: 10px;"
          >
            <!-- <img :alt="storedUserProfile.email" src="https://firebasestorage.googleapis.com/v0/b/cla-ionic-vue.appspot.com/o/assets%2Ficons%2Ffor%20moment%20garden%20-%20after%20square.jpg?alt=media&token=9fc95cc5-00eb-494b-b420-69bec465759a" /> -->
            <img :alt="storedUserProfile.email" :src="userSettings.profile.profilePicture.downloadURL" />
          </ion-avatar>

          <!-- clickToSelectFiles -->
          <!-- @click.stop="viewUserProfile" -->
          <svg
            v-else
            style="
              width: 40px;
              height: 40px;
              margin-inline-end: 10px;
              overflow: visible;
            "
          >
            <g>
              <circle fill="white" stroke-width="2" stroke="#3175eb" r="20" cx="50%" cy="50%"></circle>
              <!-- <text x="50%" y="54%" dominant-baseline="middle" text-anchor="middle" font-family="Verdana" font-size="1.4rem" fill="#3175eb">{{ ((storedUserProfile.email).slice(0,1)).toUpperCase() }}</text> -->
              <text x="50%" y="54%" dominant-baseline="middle" text-anchor="middle" font-family="Verdana" font-size="1.4rem" fill="#3175eb" v-if="this.storedUserProfile">{{ this.getFirstUpper(this.storedUserProfile.email) }}</text>
            </g>
          </svg>

          <ion-label style="font-size: 1.5rem;" v-if="userSettings && userSettings.profile && userSettings.profile.name">{{ getFullName() }}</ion-label>
          <ion-label v-else>{{ storedUserProfile.email }}</ion-label>
        </ion-item>
        <!-- </ion-chip> -->

        <!-- <ion-title>{{ storedUserProfile.email }}</ion-title> -->

      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>

        <ion-item detail="false" button class="ion-activatable" @click.stop="viewUserProfile">
          <ion-icon :icon="personOutline"></ion-icon>
          <ion-label class="ion-margin-start">Account</ion-label>
        </ion-item>

        <!-- <ion-item detail="false" button class="ion-activatable" @click="findUserByEmail"> -->
        <ion-item detail="false" button class="ion-activatable" @click.stop="viewContacts">
          <ion-icon :icon="peopleOutline"></ion-icon>
          <ion-label class="ion-margin-start">Contacts</ion-label>
          <ion-note
            slot="end"
            color="primary"
            mode="ios"
            v-if="contactCount > 0"
          >{{ contactCount }}</ion-note>
        </ion-item>

        <ion-item detail="false" button class="ion-activatable" @click.stop="viewWebrtcChat">
          <ion-icon :icon="videocamOutline"></ion-icon>
          <ion-label class="ion-margin-start">Video Call</ion-label>
        </ion-item>

        <ion-item detail="false" button class="ion-activatable" @click.stop="viewFileTransfer">
          <ion-icon :icon="folderOutline"></ion-icon>
          <ion-label class="ion-margin-start">File Transfer</ion-label>
        </ion-item>
        
        <ion-item detail="false" button class="ion-activatable" @click.stop="viewRecycling">
          <ion-icon :icon="trashBinOutline"></ion-icon>
          <ion-label class="ion-margin-start">Recycle Bin</ion-label>
          <ion-note
            slot="end"
            color="primary"
            mode="ios"
            v-if="recycleCount > 0"
          >{{ recycleCount }}</ion-note>
        </ion-item>

        <ion-item detail="false" button class="ion-activatable" @click.stop="viewFiles">
          <ion-icon :icon="folderOutline"></ion-icon>
          <ion-label class="ion-margin-start">Files</ion-label>
          <ion-note
            slot="end"
            color="primary"
            mode="ios"
            v-if="fileCount > 0"
          >{{ fileCount }}</ion-note>
        </ion-item>

        <ion-item detail="false" button disabled class="ion-activatable" @click.stop="">
          <ion-icon :icon="archiveOutline"></ion-icon>
          <ion-label class="ion-margin-start">Archived Lists</ion-label>
        </ion-item>

        <ion-item detail="false" button class="ion-activatable" @click.stop="sendPasswordReset">
          <ion-icon :icon="constructOutline"></ion-icon>
          <ion-label class="ion-margin-start">Reset Password</ion-label>
        </ion-item>

        <ion-item detail="false" button class="ion-activatable" @click.stop="">
          <ion-icon :icon="settingsOutline"></ion-icon>
          <ion-label class="ion-margin-start">Settings</ion-label>
        </ion-item>



        <ion-item detail="false" button class="ion-activatable" @click.stop="enableNetwork" v-if="true==false">
          <ion-icon :icon="cloudDoneOutline"></ion-icon>
          <ion-label class="ion-margin-start">Enable Network</ion-label>
        </ion-item>

        <ion-item detail="false" button class="ion-activatable" @click.stop="disableNetwork" v-if="true==false">
          <ion-icon :icon="cloudOfflineOutline"></ion-icon>
          <ion-label class="ion-margin-start">Disable Network</ion-label>
        </ion-item>



        <ion-item href='/' detail="false" button class="ion-activatable">
          <ion-icon :icon="refreshOutline"></ion-icon>
          <ion-label class="ion-margin-start">Refresh</ion-label>
        </ion-item>

        <ion-item detail="false" button lines="none" class="ion-activatable" v-if="isLoggedIn" @click.stop="signOutFunction">
          <ion-icon :icon="logOutOutline"></ion-icon>
          <ion-label class="ion-margin-start">Logout</ion-label>
        </ion-item>

      </ion-list>

    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main"></ion-router-outlet>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import { 
  IonContent,
  IonHeader,
  IonItem,
  IonNote,
  IonList,
  IonMenu,
  IonRouterOutlet, 
  IonToolbar,
  menuController,
  toastController,
  alertController,
  IonTitle,
  IonAvatar,
  IonImg,
  IonIcon,
  IonLabel,
  IonChip,
  // modalController,
  useIonRouter,
} from '@ionic/vue'

import {
  personOutline,
  peopleOutline,
  trashBinOutline,
  settingsOutline,
  archiveOutline,
  folderOutline,
  constructOutline,
  refreshOutline,
  logOutOutline,
  cloudDoneOutline,
  cloudOfflineOutline,
  videocamOutline,
} from 'ionicons/icons'

import { defineComponent, ref } from 'vue'
// import { db, storage, functions, storageRef, auth } from '../firebaseDb'
import { functions, auth } from '../firebaseDb'
import { onAuthStateChanged, signOut, sendPasswordResetEmail } from "firebase/auth";
// import { functions } from '../firebaseFunctions'
// import RecycleModal from "./RecycleModal.vue"
// const functions = require("firebase/functions");
import { httpsCallable } from "firebase/functions";

export default defineComponent({
  name: 'MenuLeft',
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonNote,
    IonList,
    IonMenu,
    IonRouterOutlet, 
    IonToolbar,
    IonTitle,
    IonAvatar,
    IonImg,
    IonIcon,
    IonLabel,
    IonChip,
  },
  computed: {
    ...mapState(["storedItems"]),
    ...mapState(["storedUserProfile"]),
    ...mapState(["userFiles"]),
    ...mapState(["userContacts"]),
    ...mapState(["userContacts"]),
    ...mapState(["userSettings"]),

    recycleCount() {

      function findInactive(list) {
        return list.active == false;
      }

      let firstCopy = Object.keys(this.storedItems).map(key => {
        let ar = this.storedItems[key]

        ar.key = key

        return ar
      })

      // Filter on active lists only
      var copy = firstCopy.filter(findInactive);

      return copy.length

    },
    fileCount() {

      
      if (this.userFiles &&
      this.userFiles["files"]) {

        var listsToKeep = this.userFiles["files"]
        // var listsToKeep = this.userFiles
        
        // console.log(listsToKeep)

        let firstCopy = Object.keys(listsToKeep).map(key => {
          let ar = listsToKeep[key]

          ar.key = key

          return ar
        })

        return firstCopy.length

      } else {
        return 0
      }



    },
    contactCount() {

      
      if (this.userContacts &&
      this.userContacts["contacts"]) {

        var listsToKeep = this.userContacts["contacts"]
        // var listsToKeep = this.userContacts
        
        // console.log(listsToKeep)

        let firstCopy = Object.keys(listsToKeep).map(key => {
          let ar = listsToKeep[key]

          ar.key = key

          return ar
        })

        return firstCopy.length

      } else {
        return 0
      }



    },
  },
  setup() {

    const ionRouter = useIonRouter();

    const isLoggedIn = ref(true)

    onAuthStateChanged(auth, user => {
      if (user) {
        isLoggedIn.value = true
      } else {
        console.log("Logged Out")
        isLoggedIn.value = false
      }
    })

    return {
      personOutline,
      peopleOutline,
      trashBinOutline,
      settingsOutline,
      archiveOutline,
      folderOutline,
      constructOutline,
      refreshOutline,
      logOutOutline,
      isLoggedIn,
      cloudDoneOutline,
      cloudOfflineOutline,
      videocamOutline,
      ionRouter,
    }
  },
  methods: {

    getFullName () {

      var name = this.userSettings.profile.name
      return name.first + ' ' + name.last
    },
    async openToast(header, message) {
      const toast = await toastController
        .create({
          "header": header,
          "message": message,
          "duration": 3000,
          // "mode": "md",
          "mode": "ios",
          // "translucent": true,
          "color": "medium",
          // "buttons": [
          //   {
          //     text: 'Ok',
          //     role: 'cancel',
          //     handler: () => {
          //       toast.dismiss()
          //     }
          //   }
          // ]
        })
      return toast.present();
    },
    clickToSelectFiles: function() {
      const fileElem = document.getElementById("getProfilePic");

      if (fileElem) {
        fileElem.click();
      }
    },
    checkForProfilePic() {
      if(
        this.userSettings &&
        this.userSettings.profile &&
        this.userSettings.profile.profilePicture &&
        this.userSettings.profile.profilePicture.downloadURL
      ) {
        // console.log("true")
        return true
      } else {
        // console.log("false")
        return false
      }
    },
    async viewRecycling() {

      menuController.close();

      this.ionRouter.push({
        name: 'RecycleBin',
        // params: {
        //   renameModalListId: itemID,
        //   renameModalListName: itemName,
        // }
      });

      // const modal = await modalController
      //   .create({
      //     component: RecycleModal,
      //     backdropDismiss: false,
      //     swipeToClose: false,
      //     showBackdrop: false,
      //     // presentingElement: document.getElementsByTagName("ion-router-outlet")[0],
      //     // mode: "ios",
      //     // componentProps: {
      //     //   // passedInComponent: ListItemModal,
      //     //   // data: {
      //     //     listId: listId,
      //     //     listName: listName,
      //     //     openToast: this.openToast,
      //     //   // },
      //     // },
      //   })

      // return modal.present();
    },
    getFirstUpper(email) {
      if(email) {
        // console.log(email)
        // // console.log((email.slice(0, 1)).toUpperCase())
        // var first = email.slice(0, 1);
        // var firstUpper = first.toUpperCase()
        // return firstUpper
        return (email.slice(0, 1)).toUpperCase()
      }
    },
    // async presentAlertProfilePicOptions(event, listObj) {
    async presentAlertProfilePicOptions() {

      // event.preventDefault();
      
      const alert = await alertController
        .create({
          cssClass: 'confirm-perm-delete-class',
          header: 'Are you sure you want to permanently delete this file?',
          // message: 'Message <strong>text</strong>!!!',
          // message: '<strong>This cannot be undone</strong>',
          // message: '<p><strong>' + message + '</strong></p>',
          message: '<p><h1>' + listObj.currentName + '</h1></p>',
          mode: 'ios',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-delete-list-button',
              // handler: blah => {
              //   console.log('Confirm Cancel:', blah)
              // },
            },
            {
              text: 'Yes',
              id: 'confirm-delete-list-button',
              handler: () => {
                var modal = document.querySelector('ion-modal');
                if (modal) {
                  this.closeModal()
                }
                // modalController.dismiss()
                this.deleteFile(listObj)
              },
            },
          ],
        });
      return alert.present();
    },
    async viewFiles() {

      menuController.close();

      this.ionRouter.push({
        name: 'FileView',
      });

    },
    async viewContacts() {

      menuController.close();

      this.ionRouter.push({
        name: 'Contacts',
      });

    },
    async viewWebrtcChat() {

      menuController.close();

      this.ionRouter.push({
        name: 'WebrtcChat',
      });

    },
    async viewFileTransfer() {

      menuController.close();

      this.ionRouter.push({
        name: 'FileTransfer',
      });

    },
    openFirst() {
      menuController.enable(true, 'first');
      menuController.open('first');
    },
    openEnd() {
      menuController.open('end');
    },
    openCustom() {
      menuController.enable(true, 'custom');
      menuController.open('custom');
    },

    ...mapActions(['updateStoredItems']),
    ...mapActions(['updateUserProfile']),
    signOutFunction: function () {

      let storedItemPayload = this.storedItems
      storedItemPayload = {}
      this.updateStoredItems(storedItemPayload)

      let storedUserPayload = this.storedUserProfile
      storedUserPayload = {}
      this.updateUserProfile(storedUserPayload)

      // auth.signOut()

      signOut(auth).then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });

    },
    sendPasswordReset: function () {


      onAuthStateChanged(auth, user => {
        if (user) {

          // auth.sendPasswordResetEmail(user.email)
          sendPasswordResetEmail(auth, user.email)
          .then(() => {
            // alert("Password reset email sent to: " + user.email)
            alert("Password reset email sent to: " + user.email + "Please check spam folder if not in inbox.")
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode)
            console.log(errorMessage)
          });

        }
      });

    },
    enableNetwork: function () {

      // db.enableNetwork()
      // .then(() => {
      //   alert("Network enabled")
      // });

    },
    disableNetwork: function () {

      // db.disableNetwork()
      // .then(() => {
      //   alert("Network disabled")
      // });

    },
    viewUserProfile: function () {

      menuController.close();

      this.ionRouter.push({
        name: 'UserAccount',
        // params: {
        //   renameModalListId: itemID,
        //   renameModalListName: itemName,
        // }
      });

    },

    findUserByEmail: async () => {

      var emailToLookup = prompt("Enter an email of the person you'd like to connect with")

      // let findUser =  (functions.httpsCallable(functions, 'findUser'));
      const findUser = httpsCallable(functions, 'findUser');

      let data = await findUser({ text: emailToLookup })
      .then(data  => {
        return (data.data)
      }).catch(err => {
        console.log("error:", err)
      });

      // console.log(data.result.code);
      // console.log(data.result.msg);

    }
  },
});
</script>

<style scoped>

  @media (prefers-color-scheme: dark) {
    ion-content::part(scroll) {
      border-right: 1px solid #ffffff52;
    }
  }
  .my-custom-menu {
    --width: 500px;
  }
  ion-item {
    --min-height: 4.5rem;
    font-size: 1.3rem !important;
  }
  #version {
    font-style: italic;
    color: gray;
  }
  ion-title {
    font-size: 1.3rem;
  }
  ion-toolbar {
    cursor: pointer;
    user-select: none;
    /* --background: linear-gradient(0deg, rgba(56,128,255,1) 0%, rgba(38,97,200,1) 100%); */
    --background: var(--mikes-toolbar-background);
    border-bottom: var(--mikes-nav-bottom-border);
  }
  ion-toolbar ion-title, ion-menu-button, ion-button {
    color: white;
  }
  ion-note {
    font-size: 20px;
    font-weight: bold;
  }
</style>